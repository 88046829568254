import CommonObj from "../CommonObj";

const StaticResourceText = (props) => {
  const values =
  props.resources != null ? props.resources.filter((x) => x.name === props.reskey) : [];
  const parse = require("html-react-parser");

  return (values.length > 0 && props.reskey != "TIS-DROPDOWN-TITLE"
  ? parse(values[0].value)
  : CommonObj.ResourceKeyDefaults[props.reskey]);
};
export default StaticResourceText;
