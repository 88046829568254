// import Swiper core and required modules
import {
  // Navigation,
  Pagination,
  A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import StaticResourceText from "../Format/static-resource-text";
const BlogTemplate = ({
  bottomspace,
  bgblue,
  bgWhite,
  notopspace,
  alignleft,
  blogs,
  resources,
  isPreMediaPress,
  isPt0
}) => {
  const parse = require("html-react-parser");
  return (
    <div
      className={
        (bottomspace ? "container-mb" : "") +
        (bgblue ? "section__blue" : "") +
        (bgWhite ? "bg-white" : "") +
        " bg-gray"
      }
      async
    >
      <section className={"container " + (notopspace ? "pt0" : "")}>
        {/* ***********************************************************
         ********* Section Title & Desktop Viewmore button *********
         ***********************************************************  */}
        <div className="container__title">
          <h2 className={alignleft ? "" : "align-center"}>
            <StaticResourceText
              resources={resources}
              reskey={"BLOGS-TITLE"}
            ></StaticResourceText>
          </h2>
        </div>

        {/* ***********************************************************
         ********* Blogs Carousel *********
         ***********************************************************  */}
        <div className="holder-blogs">
          <Swiper
            // install Swiper modules
            modules={[Pagination, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            // navigation
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log('slide change')}
            breakpoints={{
              750: {
                // width: 576,
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1100: {
                // width: 768,
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {blogs &&
              blogs.map((blog, i) => {
                return (
                  <SwiperSlide
                    key={blog.title}
                    className={
                      isPreMediaPress ? "swiper-slide single_half_width" : ""
                    }
                  >
                    <div className={"blog " + (bgWhite ? "gray" : "")}>
                      <div className="blog__image">
                        <img src={blog.image} alt={blog.title} />
                      </div>
                      <div className="blog__content">
                        <div className="flow">
                          <h3 className="blog__title--1 color-black">
                          {parse(blog.title)}
                          </h3>
                          <p className="blog__title--2 color-gray-dark">
                            <em>{blog.authorName}</em> -{" "}
                            {blog.date && <Date dateString={blog.date}></Date>}
                          </p>
                        </div>
                        {blog.anchorlink && (
                          <Anchor
                            className="read-more"
                            anchorText="READ MORE"
                            icon={true}
                            isPinkbtn={true}
                            link={"/blogs" + blog.anchorlink}
                          ></Anchor>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>

        {/* ***********************************************************
         ********* Small Devices Viewmore button *********
         ***********************************************************  */}
      </section>
    </div>
  );
};
export default BlogTemplate;
