// import Swiper core and required modules
import {
  A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const MarketingCarousel = ({ bottomspace, bgblue, icons }) => {
  return (
    <div
      className={
        (bottomspace ? "container-mb" : "") +
        (bgblue ? "section__blue" : "") +
        " bg-gray"
      }
      async
    >
      <section className={"container "}>
        <div className="container__title">
          <h2 className="align-center">Martech Partnerships</h2>
        </div>
        <p className="align-center  ch60 m_auto">
          Our partnerships become your advantage. As a full-service digital
          marketing solutions provider, we drive maximum value for your
          investment in:
        </p>
        <div className="holder-blogs mt-30">
          <Swiper
            // install Swiper modules
            modules={[A11y]}
            spaceBetween={20}
            slidesPerView={1}
            // navigation
            pagination={{ clickable: true }}
            breakpoints={{
              750: {
                // width: 576,
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1100: {
                // width: 768,
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {icons &&
              icons.map((icon) => {
                return (
                  <SwiperSlide key={icon.title}>
                    <div className="blog">
                      <div className="blog__image">
                        <img src={icon.image} alt={icon.title} />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </section>
    </div>
  );
};
export default MarketingCarousel;
