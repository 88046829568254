import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const ViewPortfolioButton = ({ resources }) => {
  /* NOSONAR */
  const values =
    resources != null
      ? resources.filter((x) => x.name === "GBS-PRESENTATION-BUTTON")
      : [];
  return (
    <>
      {!!values.length && (
        <div className="ps_center mt30">
          <StaticResourceText
            resources={resources}
            reskey={"GBS-PRESENTATION-BUTTON"}
          ></StaticResourceText>
        </div>
      )}
    </>
  );
};
export default ViewPortfolioButton;
