import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";

const RemediAdIconLinkSection2 = ({ icons, resources }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");

  return (
    <div className="bg-white">
      <section className="container">
        <div className="list-container--nospace">
          <StaticResourceText
            resources={resources}
            reskey={"GBS-PAS-AR-THUMBNAIL-TITLE"}
          ></StaticResourceText>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon) => {
                return (
                  <div className="icons__content" key={icon.title} async>
                    <img src={icon.image} alt={icon.title} async/>
                    <div className="align-center">
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <p className="small align-center"> <StaticResourceText resources={resources} reskey={'GBS-PAS-AR-THUMBNAIL-BOTTOM_CONTENT'}></StaticResourceText></p> */}
        </div>
      </section>
    </div>
  );
};
export default RemediAdIconLinkSection2;
