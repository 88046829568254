import React from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import CommonObj from "../CommonObj";
import { useState, useEffect } from "react";
import configJson from '../../Config.json';

const BlogListTemplate = ({ heading, type, bottomspace, searchText, sort, filter,currentPage, setCurrentPage }) => {
  const [blogs, setblogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const pageSize = configJson.PageSize;

  const fetchData = async (event) => {
    setLoading(true);
    setblogs([]);
    setLoading(true);
    let data = {
      "contentType": CommonObj.ContentType.Blogs,
      "search": searchText,
      "sort": sort,
      "pageName": CommonObj.PageNameList.Blogs,
      "pageSize": pageSize,
      "PageNo": currentPage,
      "Filter": filter
    }
    let res = await fetch(CommonObj.Endpoints.ContentListEndpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let Data = await res.json()
    setblogs(Data.contents);
    setTotalRow(Data?.contents[0]?.totalRow);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [searchText, sort, filter, currentPage]);

  const totalPages = Math.ceil(totalRow / pageSize);
  const handlePageChange = (pageNumber) => {
    window.scrollTo(400, 400);
    setCurrentPage(pageNumber);
  };
  
  const handlePreviousPage = () => {
    window.scrollTo(400, 400);
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    window.scrollTo(400, 400);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (loading) {
    return <div className="loader"><img src="/assets/img/loading.gif" /></div>;
  }

  const parse = require("html-react-parser");
  /* NOSONAR */

  if (blogs != null && blogs.length > 0) {
    return (
      <>


        <div className={(bottomspace ? "container-mb" : "") + " bg-gray mat-10"}>
          <section className="container">

            <div className={"holder-thumbnails_blog "}>
              {blogs.length &&
                blogs.map((blog) => {
                  return (
                    <a className={"carousel_blog_holder " + (blog.anchorlink == null ? "noClick" : "")} href={"/blogs" + (blog.anchorlink != null ? blog.anchorlink : "")}>
                      <div className="carousel_blog" key={blog.id}>
                        <div className="carousel__img_blog">
                          <img
                            src={(blog.authorImage!=null? blog.authorImage: "/assets/img/blog_Author_Image.jpg")}
                            alt="No Image"
                            srcSet=""
                          />
                        </div>
                        <div className="carousel__name_blog">
                          <h3 title="Blog person name">{blog.authorName}</h3>
                          <p className="color-gray-dark carousel__title--3">
                            {blog.date && (
                              <Date dateString={blog.date}></Date>
                            )}
                          </p>
                        </div>
                        <div className="carousel__content_blog flow">
                          <p className="color-black carousel__title--2 blog_title">
                            {blog.title &&
                              parse(blog.title)}
                          </p>
                        </div>
                      </div>
                    </a>
                  );
                })}
            </div>
            <div className="pagination_outer">
              <div className="pagination">
                <div className={"pagi_left_icon " + (currentPage === 1 ? "hide" : "")} onClick={handlePreviousPage} >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 6L9 12L15 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <ul>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li className={(index + 1 == currentPage) ? "active" : ""} key={index + 1} onClick={() => handlePageChange(index + 1)}> {index + 1}</li>
                  ))}
                </ul>
                <div className={"pagi_right_icon " + (currentPage === totalPages ? "hide" : "")} onClick={handleNextPage}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <span className="not-found">No Records Found</span>
      </>
    )
  }
}
export default BlogListTemplate;
