import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";
const InsuranceServicesIconSection = ({
  icons,
  resources,
  isLifeInsurance,
  resourceKey,
}) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  return (
    <div className={isLifeInsurance ? "bg-white" : "bg-gray"}>
      <section className="container">
        <div
          className={
            "list-container--nospace " +
            (isLifeInsurance
              ? "insurance_customer_outcome"
              : "ins-service-portfolio")
          }
        >
          <div className="align-center center-content mt0 mb0" async>
            <StaticResourceText
              resources={resources}
              reskey={isLifeInsurance ? resourceKey : "SWS-OT-IS-ICON1-TITLE"}
            ></StaticResourceText>
          </div>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon, i) => {
                return (
                  <a
                    className="icons__content insurance"
                    href={icon.link}
                    key={icon.title}
                  >
                    <img src={icon.image} alt={icon.title} />
                    <div className="align-center">
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default InsuranceServicesIconSection;
