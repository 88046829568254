import React from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import CommonObj from "../CommonObj";
import { useState, useEffect } from "react";
import configJson from '../../Config.json';

const CaseStudyListTemplate = ({ heading, type, bottomspace, searchText, sort, filter }) => {
  const [blogs, setblogs] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRow, setTotalRow] = useState(0);
  const pageSize = configJson.PageSize;

  const fetchData = async (event) => {
    setLoading(true);
    setblogs([]);
    let data = {
      "contentType": CommonObj.ContentType.CaseStudies,
      "search": searchText,
      "sort": sort,
      "pageName": CommonObj.PageNameList.CaseStudies,
      "pageSize": pageSize,
      "PageNo": currentPage,
      "Filter": filter
    }
    let res = await fetch(CommonObj.Endpoints.ContentListEndpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let Data = await res.json()
    setblogs(Data.contents);
    setTotalRow(Data?.contents[0]?.totalRow);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [searchText, sort, filter, currentPage]);
  const totalPages = Math.ceil(totalRow / pageSize);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const capitalizeFirstLetter = (sentence) => {
    if (!sentence) return '';
    const lowerCased = sentence.toLowerCase();
    return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
  };

  if (loading) {
    return <div className="loader"><img src="/assets/img/loading.gif" /></div>;
  }

  const parse = require("html-react-parser");
  if (blogs != null && blogs.length > 0) {
    return (
      <>
        <div className={(bottomspace ? "container-mb" : "") + " bg-gray mat-10"}>
          <section className="container casestudies">
            <div className={"holder-thumbnails "}>
              {blogs.length &&
                blogs.map((casestudies) => {
                  { console.log(casestudies) }
                  return (
                    <div className="carousel" key={casestudies.title}>
                      <div className="carousel__img">

                        <img
                          src={casestudies.image}
                          alt="{casestudies.title}"
                          srcSet=""
                        />
                      </div>
                      <div className="carousel__content flow">
                        <h3 className="color-primary carousel__title--2">
                          {casestudies.title &&
                            parse(casestudies.title)}
                        </h3>
                        <p className="color-black carousel__title--2">
                          {casestudies.shortDesc &&
                            parse(capitalizeFirstLetter(casestudies.shortDesc))}
                        </p>

                        <Anchor
                          className="read-more"
                          anchorText="VIEW SUCCESS STORY"
                          icon={true}
                          isPinkbtn={true}
                          link={"/casestudies" + casestudies.anchorlink}
                        ></Anchor>
                      </div>
                    </div>
                  );
                })}
              {/* </div> */}
            </div>
            <div className="pagination_outer">
            <div className="pagination">
              <div className={"pagi_left_icon " + (currentPage === 1 ? "hide" : "")} onClick={handlePreviousPage} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 6L9 12L15 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <ul>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li className={(index + 1 == currentPage) ? "active" : ""} key={index + 1} onClick={() => handlePageChange(index + 1)}> {index + 1}</li>
                ))}
              </ul>
              <div className={"pagi_right_icon " + (currentPage === totalPages ? "hide" : "")} onClick={handleNextPage}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 18L15 12L9 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          </section>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <span className="not-found">No Records Found</span>
      </>
    )
  }
}
export default CaseStudyListTemplate;
