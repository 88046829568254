import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const RBAIconLinkSection = ({ icons, resources }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");

  return (
    <div className="bg-white">
      <section className="container">
        <div className="list-container--nospace flow">
          <StaticResourceText
            resources={resources}
            reskey={"SWS-RBA-THUMBNAILS-TITLE"}
          ></StaticResourceText>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon, i) => {
                return (
                  <a
                    className="icons__content"
                    href={icon.link}
                    key={icon.title}
                  >
                    <img src={icon.image} alt={icon.title} />
                    <p className="align-center">
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </p>
                  </a>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default RBAIconLinkSection;
