import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";
const UtilitiesIconLinkSection = ({ icons, resources }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  return (
    <div className="bg-white">
      <section className="container">
        <div className="list-container--nospace three_d_icon_content" async>
          <StaticResourceText
            resources={resources}
            reskey={"SWS-OT-UTIL-ICON2-TITLE"}
          ></StaticResourceText>
          <div className="icons__holder mb0 mt0 ch80 m_auto" async>
            {icons != undefined  &&
              icons.map((icon) => {
                /* NOSONAR */
                return (
                  <div className="icons__content align-left" key={icon.title}>
                    <img src={icon.image} alt={icon.title} />
                    <div className="align-center" async>
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default UtilitiesIconLinkSection;
