import React from "react";
const PDParallaxContainer = () => {
  /* NOSONAR */

  return (
    <div className="parallax-container">
      <section className="container" id="presentation_services">
        <div className="list-container">
          <h2 className="align-center center-content mt0 mb0">
            What does a seamless omnichannel experience driven by data and
            supported by automation look like?
          </h2>
          <p className="ch50 center-content align-center pd-mt-10">
            A unique and unified customer experience by recomposing data from
            multiple sources.
          </p>
        </div>
      </section>
    </div>
  );
};
export default PDParallaxContainer;
