import React from "react";
const PDMarketingSolution = () => {
  /* NOSONAR */

  return (
    <div className="bg-white">
      <section className="container" id="presentation_services">
        <h2 className="align-center center-content mt0">
          Precision Dialogue’s Data-Driven Marketing Solutions
        </h2>
        <p className="align-center ch75 m_auto mt-20">
          Your marketing strategy is unique, and so is your data. So should be
          the experiences of your customers. Work with us to know how you can
          leverage the omnichannel and data simultaneously to drive growth and
          increase customer engagement.
        </p>
        <div className="icons__holder mb0 mt0 align-left">
          <div className="icons__content align-left">
            <img
              src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40870/pd-data-driven-icon1.svg"
              alt="Cloud-Native Data Management"
            />
            <div className="align-left color-primary">
              Cloud-Native Data Management
            </div>
            <ul className="align-left inside-list mt-20">
              <li>Data Management/Identity Resolution/Customer 360</li>
              <li>Data Sourcing, Cleansing &amp; Enrichment</li>
              <li>B2B &amp; B2C Lead Generation &amp; Intelligence</li>
            </ul>
          </div>
          <div className="icons__content align-left">
            <img
              src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40871/pd-data-driven-icon2.svg"
              alt="Multi-Channel Marketing &amp; Campaign Operations"
            />
            <div className="align-left color-primary">
              Multi-Channel Marketing &amp; Campaign Operations
            </div>
            <ul className="align-left inside-list mt-20">
              <li>Strategy &amp; Optimization</li>
              <li>Digital Marketing Management</li>
              <li>Global Campaign Management &amp; Operations</li>
              <li>Platform &amp; API Implementation &amp; Integration</li>
            </ul>
          </div>
          <div className="icons__content align-left">
            <img
              src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40872/pd-data-driven-icon3.svg"
              alt="Research &amp; Customer Experience"
            />
            <div className="align-left color-primary">
              Research &amp; Customer Experience
            </div>
            <ul className="align-left inside-list mt-20">
              <li>Primary Market Research</li>
              <li>Customer &amp; User Experience</li>
              <li>Journey Mapping &amp; CX Research</li>
            </ul>
          </div>
          <div className="icons__content align-left">
            <img
              src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40873/pd-data-driven-icon4.svg"
              alt="Advanced Analytics &amp; Business Intelligence Reporting"
            />
            <div className="align-left color-primary">
              Advanced Analytics &amp; Business Intelligence Reporting
            </div>
            <ul className="align-left inside-list mt-20">
              <li>
                Predictive Analytics, Machine Learning and AI across Customer
                Lifecycle
              </li>
              <li>Attribution and Reporting</li>
              <li>Search Engine Optimization for Marketing Content</li>
              <li>Google/Social Media Analytics &amp; Reporting</li>
              <li>Data Visualization</li>
            </ul>
          </div>
        </div>
        <a  
          href={"/blogs/suite-of-solutions-and-services"}
          className="m_auto mt-30 reach-us-at btnBorder"
        >
          <span>
            <p className="color-primary">LEARN MORE</p>
          </span>
          <span>
            <div className="btn__icon color-primary">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69 69">
                <g>
                  <circle
                    cx="34.25"
                    cy="34.25"
                    r="33.5"
                    fill="none"
                    strokeLinecap="round"
                  ></circle>
                  <line
                    x1="20.59"
                    y1="34.63"
                    x2="48.03"
                    y2="34.63"
                    fill="none"
                    strokeLinecap="round"
                  ></line>
                  <path
                    d="M35.7,21.75h0A31.25,31.25,0,0,0,46.64,33.41l2,1.22-1.74,1A29,29,0,0,0,36.27,47h0"
                    fill="none"
                    strokeLinecap="round"
                  ></path>
                </g>
              </svg>
            </div>
          </span>
        </a>
      </section>
    </div>
  );
};
export default PDMarketingSolution;
