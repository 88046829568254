
import Swiper,{ Autoplay } from "swiper";
function carousel(){


    function testimonialSwiper(){
         const testSwiperElement = document.querySelector('.testSwiper');
         if(!testSwiperElement) return null;
    }

    function blogSwiperFn(){

         const blogSwiperElement = document.querySelector('.blogSwiper');
         if(!blogSwiperElement) return;
    }

    function infoSwiperFn(){

         const infoSwiperElement = document.querySelector('.infoSwiper');
         if(!infoSwiperElement) return;

       let infoSwiper = new Swiper(".infoSwiper", {
         slidesPerView: 1,
         navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
         // slidesPerView: "auto",
         loop:true,
         spaceBetween: 20,
         centeredSlides: true,

         autoplay: {
              delay: 3500,
              disableOnInteraction: false,
            },
         // pagination: {
         //      el: ".swiper-pagination",
         //      clickable: true,
         //    },
            breakpoints: {
              // when window width is >= 320px
              500: {
                slidesPerView: 1.5,
                spaceBetween: 20
              },
              1100: {
                   slidesPerView: 3,
                   spaceBetween: 20
                 },
         }
       });


       document.querySelector('.swiper__prev').addEventListener('click', function(){
         infoSwiper.slidePrev();
       }, false)

       document.querySelector('.swiper__next').addEventListener('click', function(){
         infoSwiper.slideNext();
       }, false)
    }

    function heroCarouselFn(){
         const heroSwiperElement = document.querySelector('.hero-carousel');
         if(!heroSwiperElement) return;
    }
    


    infoSwiperFn();
    testimonialSwiper();
    blogSwiperFn();
    heroCarouselFn()
 }

 export default carousel;