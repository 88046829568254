import React from "react";

const Design = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1428.9 900"
      enableBackground="new 0 0 1428.9 900"
      xmlSpace="preserve"
    >
      <g transform="translate(-576.988 -1.4)" opacity="0.8">
        <path
          fill="#BA1E13"
          d="M577,165.3l648.6,545.8l80.2,67.5l0-0.1l18.5-104.5l34.7-196.6l39-220.6l45.1-255.3H997.7
                                                 L577,165.3z"
        />
        <path
          fill="#FF756C"
          d="M998,159.1l195.3,172.2l165.6,146l145.9,128.6l107.2,94.5l227.9,201h165.8V1.4h-595.9L998,159.1z"
        />
        <path
          fill="#E03C31"
          d="M900.9,437.9L1225.7,711l80.1,67.4l146.2,123h553.9V35.1L1398,256.7l-204.6,74.6L900.9,437.9z"
        />
        <path
          opacity="0.5"
          fill="#FFBF3F"
          enableBackground="new    "
          d="M953.4,813.6l102.2,87.8h521l35.5-201l25.5-144.5
                                                 l-132.8,50l-180.6,68l-98.6,37.1l-0.1,0L953.4,813.6z"
        />
      </g>
    </svg>
  );
};
export default Design;
