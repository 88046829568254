import React from "react";
import StaticResourceText from '../Format/static-resource-text';
import ThumbnailView from "./ThumbnailView";
const GOC_Parrallex_container = ({ resources, Icon1, Icon2, Icon3,Icon4 }) => {
    return (

        <div className="parallax-container">
            <section className="container" id="presentation_services">
                <div className="list-container">         
                {!!Icon1.length && (
                    <ThumbnailView
                        icons={Icon1}
                        resources={resources}
                        resourceKey={"SWS-OT-UTIL-SERVICE-PORTFOLIO"}
                        background={{ backgroundColor: "#fff" }}
                    
                    ></ThumbnailView>
                )}
                {!!Icon2.length && (
                    <ThumbnailView
                        icons={Icon2}
                        resources={resources}
                        resourceKey={"SWS-OT-UTIL-BRAND-CUSTOMER-EXPERIENCE"}
                        background={{ backgroundColor: "#fff" }}
                    ></ThumbnailView>
                )}
                {!!Icon3.length && (
                    <ThumbnailView
                        icons={Icon3}
                        resources={resources}
                        resourceKey={"SWS-OT-UTIL-TECHNOLOGY-TRANSFORMATION"}
                        background={{ backgroundColor: "#fff" }}
                    ></ThumbnailView>
                )}
                { !!Icon4 && !!Icon4.length && (
                    <ThumbnailView
                        icons={Icon4}
                        resources={resources}
                        resourceKey={"SWS-OT-UTIL-INNOVATION"}
                        background={{ backgroundColor: "#fff" }}
                    ></ThumbnailView>
                )}
                 </div>
            </section>
        </div>
    );
};
export default GOC_Parrallex_container;

