import React from "react";
import IconTemplate from "../Common/icon-template";
import StaticResourceText from "../Format/static-resource-text";
const ContentEditorialIconSection = ({ icons, resources }) => {
  return (
    <div async>
      <div className="parallax-container icons-section">
        <section className="container">
          <div className="list-container">
            <h2 className="align-center center-content mt0 mb0">
              <StaticResourceText
                resources={resources}
                reskey={"GBS-CONT_EDIT-ICONS-TITLE"}
              ></StaticResourceText>
            </h2>
            {!!icons.length && <IconTemplate icons={icons}> </IconTemplate>}
          </div>
        </section>
      </div>
    </div>
  );
};
export default ContentEditorialIconSection;
