import React, {  } from "react";

import Design from "../Icons/Design";
import Parallax from "../Common/Parallax";
const InsuranceServicesPanelSection = ({ panels }) => {
  /* NOSONAR */

  const parse = require("html-react-parser");
  return (
    <div>
      {panels &&
        panels.map((panel) => {
          return (
            <div
              className="subpagesection sub_sub_section explore_section"
              key={panel.title}
              async
            >
              <Parallax banner3={panel.image ? panel.image : ""}></Parallax>
              <div className="design__element ">
                <Design></Design>
              </div>
              <section className="container flow">
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
                <a
                  href={panel.link}
                  target="_blank"
                  className="reach-us-at mt-50"
                  rel="noopener noreferrer"
                >
                  <span>
                    <p className="ch50 color-white">Watch Videos</p>
                  </span>
                  <span className="icon-span">
                    <img
                      src="/assets/img/SVG/video_play_icon.svg"
                      alt="arrow icon"
                    />
                  </span>
                </a>
                <h3 className="color-white mt-50">RRD Insurance Services</h3>
                <p className="color-white mt-10">
                  We are your smart partner for a dynamic insurance marketplace.
                </p>
              </section>
            </div>
          );
        })}
    </div>
  );
};
export default InsuranceServicesPanelSection;
