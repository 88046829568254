import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const OperationsTransformationIconLinkSection = ({ icons, resources }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");

  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <section className="container">
        <div
          className="list-container list-container--nospace"
          style={{ backgroundColor: "#efefef" }}
        >
          <h2 className="align-center center-content mt0 mb0">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-THUMBNAILS-TITLE"}
            ></StaticResourceText>
          </h2>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon, i) => {
                return (
                  <a
                    className="icons__content"
                    href={icon.link}
                    key={icon.title}
                  >
                    <img src={icon.image} alt={icon.title} />
                    <p className="align-center">
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </p>
                  </a>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default OperationsTransformationIconLinkSection;
