function youtube(){
     const youtubeTrigger = document.querySelector('#youtubeTrigger')
     const retirementVideo = document.getElementById('retirement_video');
     if(!youtubeTrigger){
          return null;
     } else {
          const youtubepopup = document.getElementById('youtubepopup');
          const youtubeClose = document.querySelector('.youtube-mobile-close');

          youtubeTrigger.addEventListener("click", () => {
               youtubepopup.classList.add('active');
               retirementVideo.focus();
               retirementVideo.play();
          })

          youtubeClose.addEventListener('click', (e)=> {
               youtubepopup.classList.remove('active');
               youtubeTrigger.focus()
               retirementVideo.pause();
          })
     }
}

export default youtube;