import React, {  } from "react";

const VideoPopUp = ({ videos }) => {

  const parse = require("html-react-parser");

  function removespace(str) {
    return str.split(" ").join("").toLowerCase();
  }

  return (
    <div async>
      {videos &&
        videos.map((video) => {
          return (

            <aside data-popup={removespace(parse(video.title))} className="popup_container" aria-label={parse(video.title)}
              key={removespace(parse(video.title))}>
              <div className="iframe_container">
                <video controls controlsList="nodownload">
                  <source type="video/mp4" src={video.video} />
                  <track label="English" kind="captions" srcLang="en" default src={video.documentPath} />
                </video>
              </div>
              <button className="mobile__close youtube-mobile-close">
                <span className="close__dummy">close button</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
                  <circle cx="22" cy="22" r="21.25" fill="none" stroke="#a89968" strokeWidth="1.5" />
                  <line x1="10" y1="22" x2="34" y2="22" fill="none" stroke="#a89968" strokeLinecap="round" strokeWidth="1.5" />
                  <line className="linehide" x1="22" y1="10" x2="22" y2="34" fill="none" stroke="#a89968" strokeLinecap="round"
                    strokeWidth="1.5" />
                </svg>
              </button>
            </aside>
          );
        })}
    </div>
  );
};
export default VideoPopUp;
