import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const OfferSection2 = ({ resources }) => {
  /* NOSONAR */
  return (
    <div className="section__blue__white__txt">
      <section className="container">
        <StaticResourceText
          resources={resources}
          reskey={"GBS-PRESENTATION-OFFER-BUTTON-2"}
        ></StaticResourceText>
      </section>
    </div>
  );
};
export default OfferSection2;
