import React from 'react';

const Plus = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44">
            <circle cx="22" cy="22" r="21.25" fill="none" stroke="#A50050 " strokeWidth="1.5" />
            <line x1="10" y1="22" x2="34" y2="22" fill="none" stroke="#A50050 " strokeLinecap="round" strokeWidth="1.5" />
            <line className="linehide" x1="22" y1="10" x2="22" y2="34" fill="none" stroke="#A50050 " strokeLinecap="round"
                strokeWidth="1.5" />
        </svg>
    )
}
export default Plus