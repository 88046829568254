import React from 'react';

const Scroll = () => {

    return (
        <div className="field">
            <div className="mouse"></div>
            <p>Scroll</p>
        </div>
    )
}
export default Scroll