import React from "react";
import AbTriangle from "./AbTriangle";
import Date from "../Format/Date";
import StaticResourceText from "../Format/static-resource-text";

const CSHero = ({ landingpage, category, heading1, csDate, resources }) => {
  const parse = require("html-react-parser");

  return (
    <div className={"hero__wrapper hero__parallax " + landingpage} async>
      <div className="pin0"></div>
      <AbTriangle></AbTriangle>

      <section className="container">
        <div id="hero" className="hero mt-50">
          <div className="hero__content">
            <div className="title__content flow">
              <h3
                className="title__tag color-white light"
                style={{ fontWeight: "500" }}
              >
                <StaticResourceText
                  resources={resources}
                  reskey={"CASESTUDIES-PAGE-HEADING"}
                ></StaticResourceText>
              </h3>

              {heading1 && (
                <h2 className="color-white bold mt0 hero__title">
                  {parse(heading1)}
                </h2>
              )}

              {csDate && (
                <p className="color-white">
                  {csDate && <Date dateString={csDate}></Date>}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CSHero;
