import React from "react";
import Parallax from "../Common/Parallax";
import Design from "../Icons/Design";
const GBS_PS_3DSolutionsExploreSection = ({ panels }) => {
  const parse = require("html-react-parser");
  return (
    <div async>
      {panels.length &&
        panels.map((panel) => {
          /* NOSONAR */
          return (
            <div
              className="subpagesection sub_sub_section explore_section"
              key={panel.title}
            >
              <Parallax banner3={panel.image ? panel.image : ""} />
              <div className="design__element ">
                <Design></Design>
              </div>
              <section className="container flow">
                <h2 className="color-white">{panel.title}</h2>
                {panel.shortDesc && parse(panel.shortDesc)}
                <a
                  href={panel.link}
                  target="_blank"
                  className="reach-us-at mt-30"
                  rel="noopener noreferrer"
                >
                  <span>
                    <p className="ch50 color-white">View our Portfolio</p>
                  </span>
                  <span>
                    <img
                      src="/assets/img/SVG/arrow_white.svg"
                      alt="arrow icon"
                    />
                  </span>
                </a>
              </section>
            </div>
          );
        })}
    </div>
  );
};
export default GBS_PS_3DSolutionsExploreSection;
