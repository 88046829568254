import React from "react";
import StaticResourceText from "../Format/static-resource-text";
import IconTemplate from "../Common/icon-template";
const PreMediaPressServicePortfolio = ({ icons, resources }) => {
  /* NOSONAR */

  return (
    <div className="parallax-container">
      <section className="container">
        <div className="list-container flow" async>
          <h2 className="align-center center-content mt0 mb0">
            <StaticResourceText
              resources={resources}
              reskey={"SWS-OT-REIT-ICON1-TITLE"}
            ></StaticResourceText>
          </h2>
          <p className="align-center line-effect">
            <StaticResourceText
              resources={resources}
              reskey={"GBS-CP-PP-HEADER-1"}
            ></StaticResourceText>
          </p>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.includes("GBS-CP-PP-HEADER-1")
                )}
              ></IconTemplate>
            )}
          <p className="align-center line-effect">
            <StaticResourceText
              resources={resources}
              reskey={"GBS-CP-PP-HEADER-2"}
            ></StaticResourceText>
          </p>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.includes("GBS-CP-PP-HEADER-2")
                )}
              ></IconTemplate>
            )}
          <p className="align-center line-effect2">
            <StaticResourceText
              resources={resources}
              reskey={"GBS-CP-PP-HEADER-3"}
            ></StaticResourceText>
          </p>
            {icons.length && (
              <IconTemplate
                icons={icons.filter((icon) =>
                  icon.tags && icon.tags.includes("GBS-CP-PP-HEADER-3")
                )}
              ></IconTemplate>
            )}
        </div>
      </section>
    </div>
  );
};
export default PreMediaPressServicePortfolio;
