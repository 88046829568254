class DisclosureNav {
  constructor(domNode) {
    this.rootNode = domNode;
    this.triggerNodes = [];
    this.controlledNodes = [];
    this.openIndex = null;
    this.useArrowKeys = true;
  }
  init() {
    let buttons = this.rootNode.querySelectorAll('button[aria-expanded][aria-controls]');
    for (const button of buttons) {
      let menu = button.parentNode.querySelector('ul');
      if (menu) {
        // save ref to button and controlled menu
        this.triggerNodes.push(button);
        this.controlledNodes.push(menu);

        // collapse menus
        button.setAttribute('aria-expanded', 'false');
        this.toggleMenu(menu, false);

        // attach event listeners
        menu.addEventListener('keydown', this.handleMenuKeyDown.bind(this));
        button.addEventListener('click', this.handleButtonClick.bind(this));

        button.addEventListener('keydown', this.handleButtonKeyDown.bind(this));
      }
    }

    this.rootNode.addEventListener('focusout', this.handleBlur.bind(this));
  }

  toggleMenu(domNode, show) {
    if (domNode) {
      domNode.style.display = show ? 'block' : 'none';
    }
  }
  toggleExpand(index, expanded) {
    // close open menu, if applicable
    if (this.openIndex !== index) {
      this.toggleExpand(this.openIndex, false);
      this.toggleMenu(this.openIndex, false);
    }

    // handle menu at called index
    if (this.triggerNodes[index]) {
      this.openIndex = expanded ? index : null;
      this.triggerNodes[index].setAttribute('aria-expanded', expanded);
      this.toggleMenu(this.controlledNodes[index], expanded);
    }
  }
  controlFocusByKey(keyboardEvent, nodeList, currentIndex) {
    switch (keyboardEvent.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let prevIndex = Math.max(0, currentIndex - 1);
          nodeList[prevIndex].focus();
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        keyboardEvent.preventDefault();
        if (currentIndex > -1) {
          let nextIndex = Math.min(nodeList.length - 1, currentIndex + 1);
          nodeList[nextIndex].focus();
        }
        break;
      case 'Home':
        keyboardEvent.preventDefault();
        nodeList[0].focus();
        break;
      case 'End':
        keyboardEvent.preventDefault();
        nodeList[nodeList.length - 1].focus();
        break;
    }
  }
  /* Event Handlers */
  handleBlur(event) {
    let menuContainsFocus = this.rootNode.contains(event.relatedTarget);
    if (!menuContainsFocus && this.openIndex !== null) {
      this.toggleExpand(this.openIndex, false);
    }
  }
  handleButtonKeyDown(event) {
    let targetButtonIndex = this.triggerNodes.indexOf(document.activeElement);

    // close on escape
    if (event.key === 'Escape') {
      this.toggleExpand(this.openIndex, false);
    }

    // move focus into the open menu if the current menu is open
    else if (this.useArrowKeys && this.openIndex === targetButtonIndex && event.key === 'ArrowDown') {
      event.preventDefault();
      this.controlledNodes[this.openIndex].querySelector('a').focus();
    }

    // handle arrow key navigation between top-level buttons, if set
    else if (this.useArrowKeys) {
      this.controlFocusByKey(event, this.triggerNodes, targetButtonIndex);
    }
  }
  handleButtonClick(event) {
    let button = event.target;
    let buttonIndex = this.triggerNodes.indexOf(button);
    let buttonExpanded = button.getAttribute('aria-expanded') === 'true';
    this.toggleExpand(buttonIndex, !buttonExpanded);
  }
  handleMenuKeyDown(event) {
    if (this.openIndex === null) {
      return;
    }

    let menuLinks = Array.prototype.slice.call(this.controlledNodes[this.openIndex].querySelectorAll('a'));
    let currentIndex = menuLinks.indexOf(document.activeElement);

    // close on escape
    if (event.key === 'Escape') {
      this.triggerNodes[this.openIndex].focus();
      this.toggleExpand(this.openIndex, false);
    }

    // handle arrow key navigation within menu links, if set
    else if (this.useArrowKeys) {
      this.controlFocusByKey(event, menuLinks, currentIndex);
    }
  }
  // switch on/off arrow key navigation
  updateKeyControls(useArrowKeys) {
    this.useArrowKeys = useArrowKeys;
  }
}

export function navigationInit() {
  let menus = document.querySelectorAll('.disclosure-nav');
  for(let menu of menus){
    new DisclosureNav(menu).init();
  }
  window.addEventListener('resize', removeMobileMenu);
}

let menuicons = {};
let headersection = {};
let navWrapper = {};

if (typeof window !== "undefined") {
  menuicons = document.querySelector('.mobile_menu_container');
  headersection = document.querySelector('.section1');
  navWrapper = document.querySelector('.nav__wrapper');
}

export function mobileMenu() {
  document.querySelector('.mobile_menu_container').onclick = function () {
    document.querySelector('.mobile_menu_container').classList.toggle("active");
    document.querySelector('.section1').classList.toggle("active");
    document.querySelector('.nav__wrapper').classList.toggle('active');
  }
}

export function removeMobileMenu() {
  document.querySelector('.mobile_menu_container').classList.remove("active");
  document.querySelector('.section1').classList.remove("active");
  document.querySelector('.nav__wrapper').classList.remove('active');
}

export function collaspeMenu() {
  const expandedButton = document.querySelector('button[aria-expanded="true"]');
  if (expandedButton) {
    const parentLi = expandedButton.parentElement;
    const ulElement = parentLi.querySelector('ul');
    if (ulElement) {
      ulElement.style = "display:none"
    }
  }
}
