/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import React, { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CommonObj from "../CommonObj";
import ApiWithForm from '../Common/ApiWithForm';
import StaticResourceText from "../Format/static-resource-text";
import Reaptcha from "reaptcha";
import utils from "./utils";
import configJson from '../../Config.json';
import { useHistory } from "react-router-dom";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";

const Contact = ({ resources }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().
          required("Please enter the Firstname Lastname")
          .matches(/^[A-Za-z. ]+$/, "Entered Name is invalid"),
    Organisation: Yup.string().trim()
                    .required("Please enter the Organization")
                    .test('no-dangerous-tags', 'Entered Organization is invalid', value => {
                      const dangerousTags = /<script.*?>|<\/script>|<[^>]+>/gi; 
                      return !dangerousTags.test(value);
                    }),
                  
    email: Yup.string().trim()
           .required("Please enter the Official email ID")
           .email("Entered Email is invalid")
           .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email must be a valid format')
           ,
    contactNumber: Yup.string().trim().
                   required("Please enter the Contact number")
                   .matches(/^[\d()+]+$/, 'Invalid Contact Number'),
    message: Yup.string().trim().required("Please enter the Request")
                .test('no-dangerous-tags', 'Entered Request is invalid', value => {
                      const dangerousTags = /<script.*?>|<\/script>|<[^>]+>/gi; 
                       return !dangerousTags.test(value);
                 }),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const captchaRef = useRef(null);

  const [domLoaded, setDomLoaded] = React.useState(false);
  const [isPresentationService, setPresentationService] = React.useState(false);
  let popup;

  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isContactValid, setIsContactValid] = useState(false); 
  const [isOrganizationValid, setIsOrganizationValid] = useState(false);
  const [isMessageValid, setIsMessageValid] = useState(false);
  
  const [contactNumber, setContactNumber] = useState("");
  const [message, setmessage] = useState("");
  const [token, setToken] = useState("");
   const history=useHistory();
  React.useEffect(() => {
    setTimeout(async () => {
      setDomLoaded(true);
      let isPresentationService = localStorage.getItem("isPresentationService")=="true"?true:false;
      setPresentationService(isPresentationService);
      await utils.sleep(500);
      let list = document.getElementsByTagName("iframe");
      for (let ele of list) {
        ele.sandbox =
          "allow-same-origin allow-scripts allow-popups allow-forms";
      }
    }, 1000);
  }, []);

  const onSubmit = async (data) => {
    if (!validateCaptcha() || !isEmailValid || !isContactValid || !isNameValid|| !isOrganizationValid|| !isMessageValid) return;
    document.querySelector(".preload").style.display="flex";
    document.querySelector(".preload").style.opacity='';
    data.token = token;
    const [res] = await Promise.all([
      ApiWithForm(
        data,
        isPresentationService
          ? CommonObj.Endpoints.PresentationContactUs
          : CommonObj.Endpoints.AddContactUs
      ),
    ]);
    window.scrollTo(0, 0);
    hidePreLoad();
    if (res != null) {
      history.push("/thankyou");
    }
  };
  const verify = () =>{
    captchaRef.current.getResponse().then(res => {
      setToken(res)
    })

}
  function validateCaptcha() {
    if (!domLoaded) return false;
    try {
     
      if (token.length == 0) {
        document.getElementById("validCaptcha").innerHTML =
          "Please ensure you are not a robo";
        return false;
      } else {
        document.getElementById("validCaptcha").innerHTML = "";
        return true;
      }
    } catch (e) {}
  }

  function closeMobilePopUp() {
    let popup = document.querySelector(".popup_container");
    popup.classList.remove("active");
  }

  function onHandleChangeNumeric(e) {
    let value = e.target.value.replace(/[^\d()+]/g, "");
    const re = /^[\d()+]+$/;
    //if (isNaN(value)) return false;
    if (value.toString().length < 10 || value.toString().length > 20) {
      setIsContactValid(false);
      document.getElementById("contact-danger").innerHTML =
        "Invalid Contact Number";
    } 
    else if(!re.test(value)){
      setIsContactValid(false);
      document.getElementById("contact-danger").innerHTML =
        "Invalid Contact Number";
    }
    else {
      setIsContactValid(true);
      document.getElementById("contact-danger").innerHTML = "";
    }
    setContactNumber(value);
  }

  const handleName = (event) => {

    let value = event.target.value.replace(/[^a-z] /gi, "").replace(/\s+/g, ' ');
    const re = /^[A-Za-z. ]+$/;
    if (value.length == 0 )
    {
      setIsNameValid(false);
      document.getElementById("name-danger").innerHTML ="Please enter the Firstname Lastname";
    }
    else if (!re.test(value))
    {
      setIsNameValid(false);
      document.getElementById("name-danger").innerHTML ="Entered Name is invalid";
    }
    else 
    {
      document.getElementById("name-danger").innerHTML = "";
      setIsNameValid(true);
    }
    setName(value);
  };

  const handleOrganization = (event) => {
    const result = event.target.value.replace(/\s+/g, ' ');
    const re=/<script.*?>.*?<\/script>|<[^>]+>/gi;
    if (result.length == 0)
    {
      setIsOrganizationValid(false);
      document.getElementById("org-danger").innerHTML ="Please enter the Organization";
    }
    else if (re.test(result))
    {
           setIsOrganizationValid(false);
            document.getElementById("org-danger").innerHTML ="Entered Organization is invalid";
    }
    else {document.getElementById("org-danger").innerHTML = "";
    setIsOrganizationValid(true);}
    setOrganization(result);
  };

  const handleEmail = (event) => {
    try {
      let domain_name = event.target.value.split("@")[1];
      let domain = domain_name.toLowerCase();
      var regex= /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(event.target.value))
      {
          setIsEmailValid(false);
          setEmail(event.target.value);
          document.getElementById("email-danger").innerHTML ="Entered email is invalid";
          return;
      }
      if (isPresentationService) {
        setEmail(event.target.value);
        setIsEmailValid(true);
        document.getElementById("email-danger").innerHTML = "";
        return;
      }
      if (
        (domain == "gmail.com" ||
        domain == "yahoo.com" ||
        domain == "inbox.com" ||
        domain == "icloud.com" ||
        domain == "mail.com" ||
        domain == "zoho.com" ||
        domain == "aol.com" ||
        domain == "rediff.com") && !isPresentationService
      ) 
      {
        setIsEmailValid(false);
        document.getElementById("email-danger").innerHTML =
          "Please use a professional email address associated with a legitimate organization or domain.";
      } 
      else {
        setIsEmailValid(true);
        document.getElementById("email-danger").innerHTML = "";
      }
    } catch (e) {
      document.getElementById("email-danger").innerHTML =
        "Entered Email is invalid";
      setIsEmailValid(false);
    }
    setEmail(event.target.value);
  };
  const HandleChangeMessage = (event) => {
    const result = event.target.value.replace(/\s+/g, ' ');
    const re=/<script.*?>.*?<\/script>|<[^>]+>/gi;
    if (result.length == 0)
    {
      setIsMessageValid(false);
      document.getElementById("message-danger").innerHTML ="Please enter the Message";
    }
    else if (re.test(result))
    {
      setIsMessageValid(false);
            document.getElementById("message-danger").innerHTML ="Entered Message is invalid";
    }
    else {document.getElementById("message-danger").innerHTML = "";
      setIsMessageValid(true);}
      setmessage(result);
  };

  

  const placeholder_name =
    resources != null
      ? resources.filter((x) => x.name === "CU-TEXTBOX1-CAPTION")
      : [];
  const placeholder_organization =
    resources != null
      ? resources.filter((x) => x.name === "CU-TEXTBOX2-CAPTION")
      : [];
  const placeholder_emailid =
    resources != null
      ? resources.filter((x) => x.name === "CU-TEXTBOX3-CAPTION")
      : [];
  const placeholder_phoneno =
    resources != null
      ? resources.filter((x) => x.name === "CU-TEXTBOX4-CAPTION")
      : [];
  const placeholder_tellusTextArea =
    resources != null
      ? resources.filter((x) => x.name === "CU-TEXTAREA-CAPTION")
      : [];
  const parse = require("html-react-parser");

  return (
    <div async>
      <section className="form__container">
        <div className="form__inner">
          <h2>
            <StaticResourceText
              resources={resources}
              reskey={"CU-TITLE"}
            ></StaticResourceText>
          </h2>

          <form
            className="form"
            id="ContactForm"
            onSubmit={handleSubmit(onSubmit)}
          >
            <fieldset>
              <legend className="visually-hidden">
                Always available to assist you!
              </legend>
              <div className="form__fields">
                <label htmlFor="name">Name</label>
                <input
                  {...register("name")}
                  name="name"
                  className="required"
                  id="name"
                  type="text"
                  value={name}
                  onChange={handleName}
                  placeholder={
                    placeholder_name.length > 0
                      ? parse(placeholder_name[0].value)
                      : CommonObj.ResourceKeyDefaults["CU-TEXTBOX1-CAPTION"]
                  }
                  minLength={1}
                  maxLength={100}
                  style={{ resize: `none` }}
                />
                <span id="name-danger" className="text-danger">
                  {errors.name?.message}
                </span>
              </div>
              <div className="form__fields">
                <label htmlFor="org">Organization</label>
                <input
                  type="text"
                  {...register("Organisation")}
                  name="Organisation"
                  className="required"
                  id="Organization"
                  value={organization}
                  onChange={handleOrganization}
                  placeholder={
                    placeholder_organization.length > 0
                      ? parse(placeholder_organization[0].value)
                      : CommonObj.ResourceKeyDefaults["CU-TEXTBOX2-CAPTION"]
                  }
                  minLength={1}
                  maxLength={100}
                  style={{ resize: `none` }}
                />
                <span id="org-danger" className="text-danger">
                  {errors.Organisation?.message}
                </span>
              </div>

              <div className="form__fields">
                <label htmlFor="email">Official email ID</label>
                <input
                  type="email"
                  {...register("email")}
                  name="email"
                  className="required"
                  id="email"
                  value={email}
                  onChange={handleEmail}
                  onPasteCapture={handleEmail}
                  placeholder={
                    placeholder_emailid.length > 0
                      ? parse(placeholder_emailid[0].value)
                      : CommonObj.ResourceKeyDefaults["CU-TEXTBOX3-CAPTION"]
                  }
                  minLength={1}
                  maxLength={100}
                  style={{ resize: `none` }}
                />
                <span id="email-danger" className="text-danger">
                  {errors.email?.message}
                </span>
              </div>

              <div className="form__fields">
                <label htmlFor="contactNumber">Contact number</label>
                <input
                  type="tel"
                  {...register("contactNumber")}
                  name="contactNumber"
                  className="required"
                  id="contactNumber"
                  value={contactNumber}
                  onChange={onHandleChangeNumeric}
                  onPasteCapture={onHandleChangeNumeric}
                  placeholder={
                    placeholder_phoneno.length > 0
                      ? parse(placeholder_phoneno[0].value)
                      : CommonObj.ResourceKeyDefaults["CU-TEXTBOX4-CAPTION"]
                  }
                  minLength={10}
                  maxLength={20}
                  style={{ resize: `none` }}
                />
                <span id="contact-danger" className="text-danger">
                  {errors.contactNumber?.message}
                </span>
              </div>

              <div className="form__fields">
                <label htmlFor="tellus">Tell us about your request...</label>
                <textarea
                  id="message"
                  {...register("message")}
                  name="message"
                  rows="3"
                  cols="20"
                  className="required"
                  onChange={HandleChangeMessage}
                  value={message}
                  placeholder={
                    placeholder_tellusTextArea.length > 0
                      ? parse(placeholder_tellusTextArea[0].value)
                      : CommonObj.ResourceKeyDefaults["CU-TEXTAREA-CAPTION"]
                  }
                  minLength={1}
                  maxLength={2000}
                  style={{ resize: `none` }}
                ></textarea>
                <span id="message-danger" className="text-danger">{errors.message?.message}</span>
              </div>

              <div className="form__fields recaptcha-field captcha" async>
              {domLoaded && (
                  <Reaptcha sitekey={configJson.captchaSiteKey} ref={captchaRef}  onVerify={verify}  ></Reaptcha>
                )}
                <span id="validCaptcha" className="text-danger">
                  {errors.captcha?.message}
                </span>
              </div>
              <div className="form__fields">
                <button
                  className="btn btn__primary"
                  type="submit"
                  onClick={() => validateCaptcha()}
                >
                  <div className="btn__text">
                    <StaticResourceText
                      resources={resources}
                      reskey={"CU-BUTTON"}
                    ></StaticResourceText>
                  </div>
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </section>
    </div>
  );
};
export default Contact;
