import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";
import ViewPortfolioButton from "./view-portfolio-button";

const PSIconSection2 = ({
  icons,
  resources,
  title,
  isPreMediaPress,
  classes,
}) => {
  /* NOSONAR */
  const parse = require("html-react-parser");

  return (
    <div
      className={
        "bg-white " +
        (title == "GBS-PAS-PS-ICON-1-TITLE" ? "section__white__bullet" : "")
      }
    >
      <section className="container sec1">
        <div className={"list-container--nospace " + classes}>
          {!["GBS-PAS-PS-ICON-1-TITLE", "GBS-CP-PP-THUMBNAIL-TITLE"].includes(
            title
          ) && (
            <>
              <StaticResourceText
                resources={resources}
                reskey={title}
              ></StaticResourceText>

              <div className="icons__holder mb0 mt0">
                {icons &&
                  icons.map((icon) => {
                    return (
                      <div
                        className="icons__content"
                        href={icon.link}
                        key={icon.title}
                      >
                        <img src={icon.image} alt={icon.title} />
                        <div className="align-center">
                          {icon.shortDesc && parse(icon.shortDesc)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          {["GBS-PAS-PS-ICON-1-TITLE", "GBS-CP-PP-THUMBNAIL-TITLE"].includes(
            title
          ) && (
            <>
              {(!isPreMediaPress || true) && (
                <StaticResourceText
                  resources={resources}
                  reskey={title}
                ></StaticResourceText>
              )}
              <div className="icons__holder mb0 mt0">
                {icons &&
                  icons.map((icon) => {
                    return (
                      <div
                        className={
                          "icons__content" + (isPreMediaPress ? " black " : "")
                        }
                        href={icon.link}
                        key={icon.title}
                      >
                        <img src={icon.image} alt={icon.title} />
                        <div className="align-center">
                          {icon.shortDesc && parse(icon.shortDesc)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          {(!isPreMediaPress ?? true) && (
            <ViewPortfolioButton></ViewPortfolioButton>
          )}
        </div>
      </section>
    </div>
  );
};
export default PSIconSection2;
