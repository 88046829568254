export default function PeoplePgFunc(page)
{
  if (!window.location.href.includes("/blogs/") &&!window.location.href.includes("/casestudies/")) 
    {
      localStorage.setItem("ancestor", window.location.href);
    }
    var element=document.querySelector(".PgFixedImg1");
    if(page !=null && page.backgroundImage!=null)
    {
        element.classList.add("pin3")
    }
    else{
        element.classList.remove("pin3")
    }
    const interval1 = setTimeout(() => {
      var event = new Event('resize');
      window.dispatchEvent(event);
    }, 3000);
}