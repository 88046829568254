import React from 'react';

const IndexSection1 = ({ infographics }) => { /* NOSONAR */

    const parse = require('html-react-parser');
    return (
        <>
                    <div>
                        <div className="holder-carousel info_carousel" style={{ marginTop: '50px' }}>

                            <div className="arrow__container">
                                <button className="swiper__prev">
                                    <img src="/assets/img/SVG/arrow_burgundy.svg" role="button" alt="Left arrow" className="left"
                                        srcSet="" />
                                </button>
                                <div className="line"></div>
                                <button className="swiper__next">
                                    <img src="/assets/img/SVG/arrow_burgundy.svg" role="button" alt="right arrow" srcSet="" />
                                </button>
                            </div>


                            <div className="swiper infoSwiper">
                                <div className="swiper-wrapper">

                                    {infographics && infographics.map((info, i) => {
                                       
                                    return <div className="swiper-slide" key={info.title}>
                                        <div className="carousel">
                                            <div className="carousel__img bg-white">
                                                <img src={info.image} alt={info.title} />
                                            </div>
                                            <div className="carousel__content">
                                                <h3 className="color-black carousel__title--2">{info.number}</h3>
                                                <h4 className="color-primary carousel__title--1">{parse(info.shortDesc)}</h4>
                                            </div>
                                        </div>
                                    </div>})}

                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )
}
export default IndexSection1