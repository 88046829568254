
import React, { useEffect, useState } from "react";
import Parallax from "../Common/Parallax";
import CommonObj from "../CommonObj";
import hidePreLoad from "../../assets/js/Components/hidePreLoad";
import parallax, { scrollRefresh, } from "../../assets/js/Components/parallax";
import Section from "../Section/Section";
import { Helmet } from "react-helmet";
import PreLoad from "../Common/preload";
import Hero from '../Common/Hero';
import CaseStudyListTemplate from "../Common/casestudy-list-template";
import Dropdown from "../../assets/js/Components/dropdown";

export default function CaseStudiesList(props) {
    const parse = require("html-react-parser");
    const [page, setPage] = useState();
    const [searchText, setSearchText] = useState('');
    const [sort, setSort] = useState('');
    const [filter, setFilter] = useState('');
    const options = [
        CommonObj.SortType.Decending,
        CommonObj.SortType.Ascending,
    ];

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };
    const handleSort = (event) => {
        setSort(event);
    };
    const handleFilter = (event) => {
        setFilter(event);
    };
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.CaseStudies;
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
    }


    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (page != null) {
            parallax();
            scrollRefresh();

        }
        window.scrollTo(0, 0);
        hidePreLoad();
    }, []);

    if (page != null) {

        return (
            <>
                {<Section PageName={page.name} async>
                    <Helmet>
                        <title>{page.title}</title>
                        <meta name="description" content={page.metaDescription} />
                        <meta name="keywords" content={page.metaKeywords} />
                    </Helmet>
                    <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
                    {<Hero landingpage={false} heading1={page.shortDesc && parse(page.shortDesc)} resources={props.resources} contactUs={false}></Hero>}
                    <div className="pb15 bg-white ">
                        <div className="customBreadcrumbsBg container search-pt search-pb">
                            <div className="search-container">
                                <div className="search-box">
                                    <input type="text" className="search-input" placeholder="Search CaseStudy" onChange={handleSearch} />
                                </div>
                                <Dropdown
                                    options={options}
                                    placeholder="Sort By"
                                    onSelect={handleSort}
                                />
                                <Dropdown
                                    options={[
                                        CommonObj.FilterType.All,
                                        CommonObj.FilterType.Last_3_Months,
                                        CommonObj.FilterType.Last_6_Months,
                                    ]}
                                    placeholder="Filter By"
                                    onSelect={handleFilter}
                                />
                            </div>
                        </div>
                    </div>
                    <CaseStudyListTemplate heading={CommonObj.ResourceKeyDefaults['RESOURCES-CS-TITLE']}
                        bottomspace={false} searchText={searchText} sort={sort} filter={filter}  ></CaseStudyListTemplate>
                </Section>}

            </>
        );
    }
    else {
        return <PreLoad></PreLoad>;
    }
}


