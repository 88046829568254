import React, { useEffect, useState } from 'react';
import BlogTemplate from '../../Common/blog-template';
import FAQTemplate from '../../Common/faq-template';
import Hero from '../../Common/Hero';
import InfographicsTemplate from '../../Common/InfographicsTemplate';
import PageNotFound from '../../Common/PageNotFound';
import PanelTemplate from '../../Common/panel-template';
import Parallax from '../../Common/Parallax';
import TestimonialExcerptSection from '../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../Common/TestimonialTemplate';
import CommonObj from "../../CommonObj";
import IaaSIconSection from '../iaas-icon-section';
import carousel from '../../../assets/js/Components/carousel';
import faq from '../../../assets/js/Components/faq';
import formInit from '../../../assets/js/Components/formInit';
import hidePreLoad from '../../../assets/js/Components/hidePreLoad';
import { collaspeMenu } from '../../../assets/js/Components/navigation';
import parallax, { scrollRefresh } from '../../../assets/js/Components/parallax';
import resizeWindow from '../../../assets/js/Components/resizeWindow';
import heroCarousel from '../../../assets/js/Components/heroCarousel';
import Section from '../../Section/Section';
import { Helmet } from 'react-helmet';
import { CircleLoader } from 'react-spinners';
import '../../../components/loader.css';
import PreLoad from '../../Common/preload';
import CommonFunc from '../../../assets/js/Components/CommonFunc';
import { useLocation, Link } from "react-router-dom";
import BreadCrumb from '../../Common/breadcrumb';
function TIS_IAAS({ resources }) {

  const [page, setPage] = useState();
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState([0, 0]);
   const fetchData = async () => {
    let pageName = CommonObj.PageNameList.TISInnovationAsAService;
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let page = await res.json()
    setPage(page);
    CommonFunc(page);
  }
  function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
  useEffect(() => {
    // fetch call used to be here
    fetchData();
    collaspeMenu();
    PageScroller();
  }, []);


  useEffect(() => {
    if (page != null) {
      heroCarousel();
      carousel();
      faq();
      formInit();
      parallax();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();
  }, [loading]);

  if (page != null) {
    let icons = page.contents.filter(x => x.type == CommonObj.ContentType.Icons);
    let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
    const parse = require('html-react-parser');
    let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
    let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
    let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
    let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
    let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
    let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);

    return (
      <>
        {loading ?  (<PreLoad></PreLoad>) : (
           <Section PageName={page.name} async>
            <Helmet>
              <title>{page.title}</title>
              <meta name="description" content={page.metaDescription} />
              {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
            </Helmet>
            <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
            <Hero landingpage={false} category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
            <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''} ></Parallax>
                        <BreadCrumb page={page?.name}/>

            <div className="bg-white mt-1">
              <section className="container">
                <div className="inner">
                  <div className="center-content para90">
                    {page.longDesc && parse(page.longDesc)}
                    {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                  </div>
                </div>
              </section>
            </div>
            {!!panels.length && <PanelTemplate panels={panels} ></PanelTemplate>}
            {!!icons.length && <IaaSIconSection icons={icons} resources={resources}></IaaSIconSection>}
            {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
            {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={resources}></TestimonialTemplate>}
            {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={false} resources={resources}></BlogTemplate>}
            {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
          </Section>
        )}
      </>
    )
  }
  else {
    return(
      <>
      <PreLoad></PreLoad>
      {/* {loading ?  (<PreLoad></PreLoad>) : (
          <PageNotFound></PageNotFound>
      )} */}
      </>
      )
  }
}


export default TIS_IAAS;