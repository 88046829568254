import React from "react";

import StaticResourceText from "../Format/static-resource-text";
const parse = require("html-react-parser");

const ThumbnailView = ({ icons, resources, resourceKey, background,wrapperClass }) => {
    /* NOSONAR */

    return (
                <div className={wrapperClass} style={background} >
                        <StaticResourceText
                            resources={resources}
                            reskey={resourceKey}
                        ></StaticResourceText>
                    <div className="icons__holder mb0 mt0 ">
                        {icons &&
                            icons.map((icon) => {
                                return (
                                    <div className="icons__content"  key={icon.title}>
                                        <img src={icon.image} alt={icon.title} />
                                        <div className="align-center">{ !!icon.shortDesc && parse(icon.shortDesc)}</div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
    );
};
export default ThumbnailView;
