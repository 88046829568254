import React, { useState, useEffect } from 'react';
import CommonObj from '../../../CommonObj';
import heroCarousel from '../../../../assets/js/Components/heroCarousel';
import carousel from '../../../../assets/js/Components/carousel';
import faq from '../../../../assets/js/Components/faq';
import { collaspeMenu } from '../../../../assets/js/Components/navigation';
import hidePreLoad from '../../../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../../../assets/js/Components/parallax';
import resizeWindow from '../../../../assets/js/Components/resizeWindow';
import videoInit from '../../../../assets/js/Components/video';
import formInit from '../../../../assets/js/Components/formInit';
import Parallax from '../../../Common/Parallax';
import Hero from '../../../Common/Hero';
import TestimonialExcerptSection from '../../../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../../../Common/TestimonialTemplate';
import BlogTemplate from '../../../Common/blog-template';
import FAQTemplate from '../../../Common/faq-template';
import PageNotFound from '../../../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../../../Section/Section';
import PanelTemplate from '../../../Common/panel-template';
import InfographicsTemplate from '../../../Common/InfographicsTemplate';
import DesignCreativeIconSection from '../../DesignCreativeIconSection';
import GBS_PS_3DSolutionsIconSection from '../../GBS_PS_3DSolutionsIconSection';
import GBS_PS_3DSolutionsIconLinkSection from '../../GBS_PS_3DSolutionsIconLinkSection';
import GBS_PS_3DSolutionsExploreSection from '../../GBS_PS_3DSolutionsExploreSection';
import { CircleLoader } from 'react-spinners';
import '../../../../components/loader.css'
import PreLoad from '../../../Common/preload';
import CommonFunc from '../../../../assets/js/Components/CommonFunc';


function GBS_PS_3DSolutions({ resources }) {

    const [page, setPage] = useState();
    const [size, setSize] = useState([0, 0]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        let pageName = CommonObj.PageNameList.GBSCreativeProduction3DSolutions;
        console.log(JSON.stringify(pageName));
        console.log(CommonObj.Endpoints.GetPage);
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify(pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    function PageScroller()
    {
      const timer = setTimeout(() => 
      {
        setLoading(false);
      }, 3000);
    }


    useEffect(() => {
        // fetch call used to be here
        fetchData();
        collaspeMenu();
        PageScroller();
    }, []);

    useEffect(() => {
        if (page != null) {
            heroCarousel();
            carousel();
            faq();
            formInit();
            parallax();
            videoInit();
        }
        window.scrollTo(0, 0);
        hidePreLoad();
        resizeWindow();
    }, [loading])


    if (page != null) {
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials)
        let icons = page.contents.filter(x => x.type == CommonObj.ContentType.Icons);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        const parse = require('html-react-parser');
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
        let outcomeicons = page.contents.filter(x => x.type == CommonObj.ContentType.Thumbnails);
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);

        return (
            <>
                {loading ? (<PreLoad></PreLoad>) : (
                    <Section PageName={page.name}>
                        <Helmet>
                            <title>{page.title}</title>
                            <meta name="description" content={page.metaDescription} />
                            {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
                        </Helmet>
                        <Parallax pageheroimage={page.image ? page.image : ''} ></Parallax>
                        <Hero landingpage={false} category={page.heading} heading1={page.shortDesc} carouselList={heroCarouselList} resources={resources}></Hero>
                        <Parallax pageFixedImage={page.backgroundImage ? page.backgroundImage : ''} ></Parallax>
                        <div className="bg-white">
                            <section className="container">
                                <div className="inner">
                                    <div className="center-content para90">
                                        {page.longDesc && parse(page.longDesc)}
                                        {!!infographics.length && <InfographicsTemplate infographics={infographics} ></InfographicsTemplate>}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {!!icons.length && <GBS_PS_3DSolutionsIconSection icons={icons} resources={resources}></GBS_PS_3DSolutionsIconSection>}
                        {!!outcomeicons.length && <GBS_PS_3DSolutionsIconLinkSection icons={outcomeicons} resources={resources}></GBS_PS_3DSolutionsIconLinkSection>}
                        {!!panels.length && <GBS_PS_3DSolutionsExploreSection panels={panels}></GBS_PS_3DSolutionsExploreSection>}
                        {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={resources}></TestimonialExcerptSection>}
                        {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} notopspace={true} resources={resources}></TestimonialTemplate>}
                        {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={true} resources={resources}></BlogTemplate>}
                        {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={resources}></FAQTemplate>}
                    </Section>
                )}
            </>
        )
    }
    else {
        return (

            <>
            <PreLoad></PreLoad>
                {/* {loading ? (<PreLoad></PreLoad>) : (
                    <PageNotFound></PageNotFound>
                )} */}
            </>
        )
    }
}

export default GBS_PS_3DSolutions;