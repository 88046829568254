import React from 'react';

const WorldMap = () => {
    return (
        <>

            <svg version="1.1" id="globemap" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 1919 939.5" enableBackground="new 0 0 1919 939.5" xmlSpace="preserve">
                <image overflow="visible" width="3840" height="1880" id="mapimg" xlinkHref="/assets/img/Map_Bg.jpg" transform="matrix(0.4997 0 0 0.4997 0 0)">
                </image>
                <g id="Atlantagroup" transform="translate(-498.698 -3503.311)">
                    <g id="Atlanta" transform="translate(546.835 3562.709)">
                        <g transform="matrix(1, 0, 0, 1, -48.14, -59.4)">
                            <path id="Rectangle_387-2" fill="#FFFFFF" d="M563.7,446.2h107c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-107c-11,0-20-9-20-20l0,0
				C543.7,455.2,552.7,446.2,563.7,446.2z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 518.5798 412.8421)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Atlanta, GA</text>
                    </g>
                    <g id="Atlantaline" transform="matrix(1, 0, 0, 1, 498.7, 3503.31)">
                        <path id="Path_1353-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M543.7,466.2c-29.5,0-28.4-19.1-28.4-53.5" />
                    </g>
                    <g id="Atlantapin" transform="translate(507.698 3509.311)">
                        <g transform="matrix(1, 0, 0, 1, -9, -6)">
                            <path id="Path_1333-2" fill="#FFFFFF" d="M515.3,392.8c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2s-10.7-6.3-10.7-17.2
				C504.6,397.6,509.4,392.8,515.3,392.8C515.3,392.8,515.3,392.8,515.3,392.8z"/>
                        </g>
                        <g id="Group_1312" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279" fill="#FF7A3F" cx="504.2" cy="395.1" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_1_" cx="504.2" cy="395.1" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_2_">
                                    <use xlinkHref="#SVGID_1_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48" transform="translate(0 0)" clipPath="url(#SVGID_2_)">
                                    <g id="Group_1278" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M514.5,430.7l78-43.9h6.6v39.3
							L514.5,430.7z"/>
                                        <path id="Path_1306" fill="#E03C31" d="M514.6,430.4l-19-5.9v-37.7h26.6l17.5,43.6H514.6z" />
                                        <path id="Path_1307" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M513.6,428.6l-10.3-28.2l38.5,28.2H513.6
							z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Chanhassengroup" transform="translate(-451 -3333.31)">
                    <g id="Chanhassen" transform="translate(527.422 3339.31)">
                        <g transform="matrix(1, 0, 0, 1, -76.42, -6)">
                            <path id="Rectangle_387-2_1_" fill="#FFFFFF" d="M546.4,220.9h156c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-156c-11,0-20-9-20-20
				l0,0C526.4,229.9,535.3,220.9,546.4,220.9z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 472.9373 240.9111)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Chanhassen, MN</text>
                    </g>
                    <g id="Chanhassenline" transform="matrix(1, 0, 0, 1, 451, 3333.31)">
                        <path id="Path_1351-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M526.4,241.2c-58.8,0-56.7,38.1-56.7,106.8" />
                    </g>
                    <g id="Chanhassenpin" transform="translate(460 3458.271)">
                        <g transform="matrix(1, 0, 0, 1, -9, -124.96)">
                            <path id="Path_1333-2_1_" fill="#FFFFFF" d="M469.7,339.9c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C458.9,344.7,463.7,339.9,469.7,339.9C469.7,339.9,469.7,339.9,469.7,339.9z"/>
                        </g>
                        <g id="Group_1312_1_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_1_" fill="#FF7A3F" cx="458.5" cy="223.2" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_3_" cx="458.5" cy="223.2" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_4_">
                                    <use xlinkHref="#SVGID_3_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_1_" transform="translate(0 0)" clipPath="url(#SVGID_4_)">
                                    <g id="Group_1278_1_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_1_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M468.8,258.8l78-43.9h6.6v39.3
							L468.8,258.8z"/>
                                        <path id="Path_1306_1_" fill="#E03C31" d="M469,258.5l-19-5.9v-37.7h26.6l17.5,43.6H469z" />
                                        <path id="Path_1307_1_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M468,256.7l-10.3-28.2l38.5,28.2H468z
							"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Chennaigroup" transform="translate(-1246.276 -3485.089)">
                    <g id="Chennai" transform="translate(1322.698 3491.089)">
                        <g transform="matrix(1, 0, 0, 1, -76.42, -6)">
                            <path id="Rectangle_387-2_2_" fill="#FFFFFF" d="M1339.9,378.6h81c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-81c-11,0-20-9-20-20l0,0
				C1319.9,387.5,1328.8,378.6,1339.9,378.6z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 1266.4373 398.5676)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Chennai</text>
                    </g>
                    <g id="Chennailine" transform="matrix(1, 0, 0, 1, 1246.28, 3485.09)">
                        <path id="Path_1355-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M1319.9,398.8c-58.8,0-56.7,38.1-56.7,106.8" />
                    </g>
                    <g id="Chennaipin" transform="translate(1255.276 3606.737)">
                        <g transform="matrix(1, 0, 0, 1, -9, -121.65)">
                            <path id="Path_1333-2_2_" fill="#FFFFFF" d="M1263.2,494.2c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C1252.4,499,1257.2,494.2,1263.2,494.2C1263.2,494.2,1263.2,494.2,1263.2,494.2z"/>
                        </g>
                        <g id="Group_1312_2_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_2_" fill="#FF7A3F" cx="1252" cy="380.9" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_5_" cx="1252" cy="380.9" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_6_">
                                    <use xlinkHref="#SVGID_5_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_2_" transform="translate(0 0)" clipPath="url(#SVGID_6_)">
                                    <g id="Group_1278_2_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_2_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M1262.3,416.5l78-43.9h6.6v39.3
							L1262.3,416.5z"/>
                                        <path id="Path_1306_2_" fill="#E03C31" d="M1262.5,416.2l-19-5.9v-37.7h26.6l17.5,43.6H1262.5z" />
                                        <path id="Path_1307_2_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M1261.5,414.4l-10.3-28.2l38.5,28.2
							H1261.5z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Colombogroup" transform="translate(-1260.276 -3632.737)">
                    <g id="Colombo" transform="translate(1308.413 3684.119)">
                        <g transform="matrix(1, 0, 0, 1, -48.14, -51.38)">
                            <path id="Rectangle_387-2_3_" fill="#FFFFFF" d="M1323.2,569.2h88c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-88c-11,0-20-9-20-20l0,0
				C1303.2,578.2,1312.2,569.2,1323.2,569.2z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 1278.0797 543.8502)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Colombo</text>
                    </g>
                    <g id="Colombline" transform="matrix(1, 0, 0, 1, 1260.28, 3632.74)">
                        <path id="Path_1356-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M1303.2,589.2c-29.5,0-28.4-19.1-28.4-53.5" />
                    </g>
                    <g id="Colombpin" transform="translate(1269.276 3638.737)">
                        <g transform="matrix(1, 0, 0, 1, -9, -6)">
                            <path id="Path_1333-2_3_" fill="#FFFFFF" d="M1274.8,523.9c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C1264.1,528.7,1268.9,523.9,1274.8,523.9C1274.8,523.9,1274.8,523.9,1274.8,523.9z"/>
                        </g>
                        <g id="Group_1312_3_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_3_" fill="#FF7A3F" cx="1263.7" cy="526.1" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_7_" cx="1263.7" cy="526.1" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_8_">
                                    <use xlinkHref="#SVGID_7_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_3_" transform="translate(0 0)" clipPath="url(#SVGID_8_)">
                                    <g id="Group_1278_3_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_3_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M1274,561.8l78-43.9h6.6v39.3
							L1274,561.8z"/>
                                        <path id="Path_1306_3_" fill="#E03C31" d="M1274.1,561.5l-19-5.9v-37.7h26.6l17.5,43.6H1274.1z" />
                                        <path id="Path_1307_3_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M1273.1,559.6l-10.3-28.2l38.5,28.2
							H1273.1z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Manilagroup" transform="translate(-1442.276 -3563.089)">
                    <g id="Manila" transform="translate(1488.413 3569.089)">
                        <g transform="matrix(1, 0, 0, 1, -46.14, -6)">
                            <path id="Rectangle_387-2_4_" fill="#FFFFFF" d="M1511.3,453.8h66c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-66c-11,0-20-9-20-20l0,0
				C1491.3,462.7,1500.3,453.8,1511.3,453.8z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 1468.1711 473.7811)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Manila</text>
                    </g>
                    <g id="Manilaline" transform="matrix(1, 0, 0, 1, 1442.28, 3563.09)">
                        <path id="Path_1357-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M1491.3,473.8c-29.5,0-28.4,19.1-28.4,53.5" />
                    </g>
                    <g id="Manilapin" transform="translate(1451.276 3629.338)">
                        <g transform="matrix(1, 0, 0, 1, -9, -66.25)">
                            <path id="Path_1333-2_4_" fill="#FFFFFF" d="M1464.9,514c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C1454.2,518.8,1459,514,1464.9,514C1464.9,514,1464.9,514,1464.9,514z"/>
                        </g>
                        <g id="Group_1312_4_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_4_" fill="#FF7A3F" cx="1453.8" cy="456.1" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_9_" cx="1453.8" cy="456.1" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_10_">
                                    <use xlinkHref="#SVGID_9_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_4_" transform="translate(0 0)" clipPath="url(#SVGID_10_)">
                                    <g id="Group_1278_4_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_4_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M1464.1,491.7l78-43.9h6.6V487
							L1464.1,491.7z"/>
                                        <path id="Path_1306_4_" fill="#E03C31" d="M1464.2,491.4l-19-5.9v-37.7h26.6l17.5,43.6H1464.2z" />
                                        <path id="Path_1307_4_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M1463.2,489.6l-10.3-28.2l38.5,28.2
							H1463.2z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="NYgroup" transform="translate(-558.276 -3392.641)">
                    <g id="NY" transform="translate(606.413 3398.641)">
                        <g transform="matrix(1, 0, 0, 1, -48.14, -6)">
                            <path id="Rectangle_387-2_5_" fill="#FFFFFF" d="M634.8,284.1h127c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-127c-11,0-20-9-20-20
				l0,0C614.8,293,623.8,284.1,634.8,284.1z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 589.6711 304.0705)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">New York, NY</text>
                    </g>
                    <g id="NYline" transform="matrix(1, 0, 0, 1, 558.28, 3392.64)">
                        <path id="Path_1352-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M614.8,304.1c-29.5,0-28.4,19.1-28.4,53.5" />
                    </g>
                    <g id="NYpin" transform="translate(567.276 3466.311)">
                        <g transform="matrix(1, 0, 0, 1, -9, -73.67)">
                            <path id="Path_1333-2_5_" fill="#FFFFFF" d="M586.4,351.7c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C575.7,356.5,580.5,351.7,586.4,351.7C586.4,351.7,586.4,351.7,586.4,351.7z"/>
                        </g>
                        <g id="Group_1312_5_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_5_" fill="#FF7A3F" cx="575.3" cy="286.4" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_11_" cx="575.3" cy="286.4" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_12_">
                                    <use xlinkHref="#SVGID_11_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_5_" transform="translate(0 0)" clipPath="url(#SVGID_12_)">
                                    <g id="Group_1278_5_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_5_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M585.6,322l78-43.9h6.6v39.3
							L585.6,322z"/>
                                        <path id="Path_1306_5_" fill="#E03C31" d="M585.7,321.7l-19-5.9v-37.7h26.6l17.5,43.6H585.7z" />
                                        <path id="Path_1307_5_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M584.7,319.9l-10.3-28.2l38.5,28.2
							H584.7z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Phoenixgroup" transform="translate(-253.811 -3506.311)">
                    <g id="Phoenix" transform="translate(262.811 3565.709)">
                        <g transform="matrix(1, 0, 0, 1, -9, -59.4)">
                            <path id="Rectangle_387-2_6_" fill="#FFFFFF" d="M281.1,446.8h80c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-80c-11,0-20-9-20-20l0,0
				C261.1,455.7,270.1,446.8,281.1,446.8z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 275.1296 413.356)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Phoenix</text>
                    </g>
                    <g id="phoenixline" transform="matrix(1, 0, 0, 1, 253.81, 3506.31)">
                        <path id="Path_1358-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M381.1,466.8c29.5,0,28.4-19.1,28.4-53.5" />
                    </g>
                    <g id="phoenixpin" transform="translate(400.499 3512.311)">
                        <g transform="matrix(1, 0, 0, 1, -146.69, -6)">
                            <path id="Path_1333-2_6_" fill="#FFFFFF" d="M409.5,393.4c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2s-10.7-6.3-10.7-17.2
				C398.8,398.2,403.6,393.4,409.5,393.4C409.5,393.4,409.5,393.4,409.5,393.4z"/>
                        </g>
                        <g id="Group_1312_6_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_6_" fill="#FF7A3F" cx="260.7" cy="395.7" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_13_" cx="260.7" cy="395.7" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_14_">
                                    <use xlinkHref="#SVGID_13_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_6_" transform="translate(0 0)" clipPath="url(#SVGID_14_)">
                                    <g id="Group_1278_6_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_6_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M271,431.3l78-43.9h6.6v39.3
							L271,431.3z"/>
                                        <path id="Path_1306_6_" fill="#E03C31" d="M271.2,431l-19-5.9v-37.7h26.6l17.5,43.6H271.2z" />
                                        <path id="Path_1307_6_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M270.2,429.1L259.9,401l38.5,28.2
							H270.2z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Portlandgroup" transform="translate(-159 -3322.272)">
                    <g id="Portland" transform="translate(168 3328.272)">
                        <g transform="matrix(1, 0, 0, 1, -9, -6)">
                            <path id="Rectangle_387-2_7_" fill="#FFFFFF" d="M191.6,209.9h121c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-121c-11,0-20-9-20-20
				l0,0C171.6,218.8,180.5,209.9,191.6,209.9z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 185.5856 229.8759)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Portland, OR</text>
                    </g>
                    <g id="portlandline" transform="matrix(1, 0, 0, 1, 159, 3322.27)">
                        <path id="Path_1350-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M332.4,230.1c58.8,0,56.7,38.1,56.7,106.8" />
                    </g>
                    <g id="portlandpin" transform="translate(374.776 3444.331)">
                        <g transform="matrix(1, 0, 0, 1, -215.78, -122.06)">
                            <path id="Path_1333-2_7_" fill="#FFFFFF" d="M389.1,325.9c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2s-10.7-6.3-10.7-17.2
				C378.4,330.7,383.2,325.9,389.1,325.9C389.1,325.9,389.1,325.9,389.1,325.9z"/>
                        </g>
                        <g id="Group_1312_7_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_7_" fill="#FF7A3F" cx="171.2" cy="212.2" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_15_" cx="171.2" cy="212.2" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_16_">
                                    <use xlinkHref="#SVGID_15_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_7_" transform="translate(0 0)" clipPath="url(#SVGID_16_)">
                                    <g id="Group_1278_7_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_7_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M181.5,247.8l78-43.9h6.6v39.3
							L181.5,247.8z"/>
                                        <path id="Path_1306_7_" fill="#E03C31" d="M181.6,247.5l-19-5.9v-37.7h26.6l17.5,43.6H181.6z" />
                                        <path id="Path_1307_7_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M180.6,245.7l-10.3-28.2l38.5,28.2
							H180.6z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Trivandrumgroup" transform="translate(-1043.885 -3534.97)">
                    <g id="Trivandrum" transform="translate(1052.885 3540.97)">
                        <g transform="matrix(1, 0, 0, 1, -9, -6)">
                            <path id="Rectangle_387-2_8_" fill="#FFFFFF" d="M1078.3,431h111c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-111c-11,0-20-9-20-20l0,0
				C1058.3,440,1067.2,431,1078.3,431z"/>
                        </g>
                        <text transform="matrix(1 0 0 1 1072.2781 451.0219)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px">Trivandrum</text>
                    </g>
                    <g id="tvrline" transform="matrix(1, 0, 0, 1, 1043.88, 3534.97)">
                        <path id="Path_1354-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M1209.3,451c40.5,0,39.1,26.3,39.1,73.6" />
                    </g>
                    <g id="tvrpin" transform="translate(1232.276 3622.737)">
                        <g transform="matrix(1, 0, 0, 1, -188.39, -87.77)">
                            <path id="Path_1333-2_8_" fill="#FFFFFF" d="M1248.4,512.8c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
				c-2.7,0-10.7-6.3-10.7-17.2C1237.7,517.6,1242.5,512.8,1248.4,512.8C1248.4,512.8,1248.4,512.8,1248.4,512.8z"/>
                        </g>
                        <g id="Group_1312_8_" transform="translate(2.145 2.145)">
                            <ellipse id="Ellipse_279_8_" fill="#FF7A3F" cx="1057.9" cy="433.3" rx="9" ry="8.5" />
                            <g>
                                <defs>
                                    <ellipse id="SVGID_17_" cx="1057.9" cy="433.3" rx="9" ry="8.5" />
                                </defs>
                                <clipPath id="SVGID_18_">
                                    <use xlinkHref="#SVGID_17_" overflow="visible" />
                                </clipPath>
                                <g id="Mask_Group_48_8_" transform="translate(0 0)" clipPath="url(#SVGID_18_)">
                                    <g id="Group_1278_8_" transform="translate(-29.052 -22.562)">
                                        <path id="Path_1305_8_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M1068.2,468.9l78-43.9h6.6v39.3
							L1068.2,468.9z"/>
                                        <path id="Path_1306_8_" fill="#E03C31" d="M1068.3,468.6l-19-5.9V425h26.6l17.5,43.6H1068.3z" />
                                        <path id="Path_1307_8_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M1067.3,466.8l-10.3-28.2l38.5,28.2
							H1067.3z"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Tokyogroup" transform="translate(-1375.276 -3693.089)" >
                    <g id="Tokyo" transform="translate(1488.413 3569.089)" >
                        <g transform="matrix(1, 0, 0, 1, -46.14, -6)" >
                            <path id="Rectangle_387-2_4_" fill="#FFFFFF" d="M1511.3,453.8h66c11,0,20,9,20,20l0,0c0,11-9,20-20,20h-66c-11,0-20-9-20-20l0,0
                                C1491.3,462.7,1500.3,453.8,1511.3,453.8z" ></path>
                        </g>
                        <text transform="matrix(1 0 0 1 1468.1711 473.7811)" fill="#006298" fontFamily="'Montserrat-SemiBold'" fontSize="18px" >Tokyo</text>
                    </g>
                    <g id="Tokyoline" transform="matrix(1, 0, 0, 1, 1442.28, 3563.09)" >
                        <path id="Path_1357-2" fill="none" stroke="#FFFFFF" strokeWidth="2" d="M1491.3,473.8c-29.5,0-28.4,19.1-28.4,53.5" ></path>
                    </g>
                    <g id="Tokyopin" transform="translate(1451.276 3629.338)" >
                        <g transform="matrix(1, 0, 0, 1, -9, -66.25)" >
                            <path id="Path_1333-2_4_" fill="#FFFFFF" d="M1464.9,514c5.9,0,10.7,4.8,10.7,10.7c0,10.6-8,17.2-10.7,17.2
                                c-2.7,0-10.7-6.3-10.7-17.2C1454.2,518.8,1459,514,1464.9,514C1464.9,514,1464.9,514,1464.9,514z" ></path>
                        </g>
                        <g id="Group_1312_4_" transform="translate(2.145 2.145)" >
                            <ellipse id="Ellipse_279_4_" fill="#FF7A3F" cx="1453.8" cy="456.1" rx="9" ry="8.5" ></ellipse>
                            <g >
                                <defs >
                                    <ellipse id="SVGID_9_" cx="1453.8" cy="456.1" rx="9" ry="8.5" ></ellipse>
                                </defs>
                                <clipPath id="SVGID_10_" >
                                    <use xlinkHref="#SVGID_9_" overflow="visible" ></use>
                                </clipPath>
                                <g id="Mask_Group_48_4_" transform="translate(0 0)" clipPath="url(#SVGID_10_)" >
                                    <g id="Group_1278_4_" transform="translate(-29.052 -22.562)" >
                                        <path id="Path_1305_4_" opacity="0.747" fill="#E03C31" enableBackground="new    " d="M1464.1,491.7l78-43.9h6.6V487
                                            L1464.1,491.7z" ></path>
                                        <path id="Path_1306_4_" fill="#E03C31" d="M1464.2,491.4l-19-5.9v-37.7h26.6l17.5,43.6H1464.2z" ></path>
                                        <path id="Path_1307_4_" opacity="0.997" fill="#FFBF3F" enableBackground="new    " d="M1463.2,489.6l-10.3-28.2l38.5,28.2
                                            H1463.2z" ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>


        </>
    )
}
export default WorldMap