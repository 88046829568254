
import './globals.css';
import React,{ useState ,useEffect} from "react";
import Header from './components/Common/Header';
import MainSection from './components/MainSection/MainSection';
import SwiperCore, { Autoplay } from 'swiper';


const App = ()  =>{ 
  SwiperCore.use([Autoplay])
  return (
    <>
    <Header/> 
    <MainSection/>
    
    </>
    
  );
}

export default App;
