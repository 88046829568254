import React from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";

const ResourceTemplate = ({ heading, smallthumbnails, type, bottomspace }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  return (
    <div className={(bottomspace ? "container-mb" : "") + " bg-gray mat-10"}>
      <section className="container">
        <div className="container__title">
          <h2>{heading}</h2>
        </div>

        <div className={"holder-thumbnails " + type}>
          {smallthumbnails.length &&
            smallthumbnails.map((smallthumbnail) => {
              return (
                <div className="carousel" key={smallthumbnail.title}>
                  <div className="carousel__img">
                    <img
                      src={smallthumbnail.image}
                      alt="{smallthumbnail.title}"
                      srcSet=""
                    />
                  </div>
                  <div className="carousel__content flow">
                    <h3 className="color-black carousel__title--2">
                      {smallthumbnail.shortDesc &&
                        parse(smallthumbnail.shortDesc)}
                    </h3>
                    <p className="color-gray-dark carousel__title--3">
                      {smallthumbnail.date && (
                        <Date dateString={smallthumbnail.date}></Date>
                      )}
                    </p>

                    <Anchor
                      className="read-more"
                      anchorText="READ MORE"
                      icon={true}
                      isPinkbtn={true}
                      link={"/casestudies" + smallthumbnail.anchorlink}
                    ></Anchor>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );
};
export default ResourceTemplate;
