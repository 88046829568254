import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
import StaticResourceText from "../Format/static-resource-text";

const OnOurMantelpiece = ({ thumbnails, resources, title }) => {
  const parse = require("html-react-parser");

  return (
    <div className="  bg_gray_light">
      <section className="container ">
        <StaticResourceText
          resources={resources}
          reskey={title}
        ></StaticResourceText>

        <br />
        <br />
        <div className="container-mb parallax-container icons-section thumbnail-section">
          <div className="holder-carousel">
            <Swiper
              modules={[Pagination, A11y]}
              pagination={{ clickable: true }}
              className="holder-thumbnail on-our-mental-piece"
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                750: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
            >
              {thumbnails != undefined &&
                thumbnails.map((icon) => {
                  return (
                    <SwiperSlide key={icon.title + icon.image}>
                      <div className="thumbnail mar-rem2" async>
                        <div className="on-our-mental-piece-img">
                          <img src={icon.image} alt={icon.title + " icon"} />
                        </div>
                        <div className="blog__content flow">
                          {icon.shortDesc && parse(icon.shortDesc)}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OnOurMantelpiece;
