import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";

const LifeInsuranceIcons = ({ icons, resources, title }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  return (
    <div className="parallax-container">
      <section className="container">
        <div className="list-container insurance_services" async>
          <StaticResourceText
            resources={resources}
            reskey={title}
          ></StaticResourceText>

          <div className="icons__holder mb0 mt0" async>
            {icons &&
              icons.map((icon) => {
                return (
                  <a
                    className="icons__content"
                    href={icon.link}
                    key={icon.title}
                  >
                    <img src={icon.image} alt={icon.title} />
                    <div className="align-center icon">
                      {icon.shortDesc && parse(icon.shortDesc)}
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default LifeInsuranceIcons;
