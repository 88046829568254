import React from "react";
const PDIcons = () => {
  /* NOSONAR */

  return (
    <div className="bg-white">
      <section className="container">
        <div className="three_d_icon_content legal_services">
          <h2 className="align-center center-content mt0">
            PD360 Solution Benefits
          </h2>
          <div className="icons__holder mb0 mt0">
            <div className="icons__content">
              <img
                src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40874/pd-suite-icon1.svg"
                alt="PD360 Solution Benefits icon 1"
              />
              <p>
                Work with existing best-of-breed technology or applications that
                deliver increased control and flexibility
              </p>
            </div>

            <div className="icons__content">
              <img
                src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40875/pd-suite-icon2.svg"
                alt="PD360 Solution Benefits  icon 2"
              />
              <p>
                Reduce the risk for data security and access all data without
                copying, synching or mapping
              </p>
            </div>
            <div className="icons__content">
              <img
                src="https://gorrd-dev-gocms-s3-data.s3.amazonaws.com/ContentDocument/40876/pd-suite-icon3.svg"
                alt="PD360 Solution Benefits  icon 3"
              />
              <p>
                Use live data for segmentation and immediate activation on any
                channel
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default PDIcons;
