function VideoPopUp() {
  let videoPlayLink = document.querySelectorAll(".video_play_link");
  if (!videoPlayLink) return null;

  videoPlayLink.forEach(function (playLink) {
    playLink.addEventListener("click", function (t) {
      t.preventDefault();
      let e = this.getAttribute("data-target");
      playVideo(e);
    });
  });

  let e = document.querySelectorAll(".mobile__close");
  e &&
    e.forEach(function (t) {
      t.addEventListener(
        "click",
        function () {
          const video = document.querySelector(".popup_container.active video");
          if (!video) return;
          pauseVideo(video);
        },
        !1
      );
    });
}

export default VideoPopUp;

function pauseVideo(video) {
  video.pause();
  document.querySelector(".popup_container.active").classList.remove("active");
}




function playVideo(e) {
  document
    .querySelector("[data-popup = '".concat(e, "']"))
    .classList.add("active");
  document.querySelector("[data-popup = '".concat(e, "'] video")).play();
}
