import React from 'react';
import Arrow from './Arrow';

const LearnMore = ({ className, buttonText, link }) => {
    return (
        <>
            <a rel="noopener noreferrer learnmore" href={link} className={'learnMore newLearnMore ' + className}>
                <div className="btn__text">{buttonText}</div>
                <span className="btn__icon ">
                    <Arrow></Arrow>
                </span>
            </a>
        </>
    )
}
export default LearnMore