import React from "react";

const PageNotFound = () => {
  return (
    <div
      style={{ textAlign: "center", alignItems: "center", padding: "70px 0" }}
    >
      <h1
        className="next-error-h1"
        style={{
          display: "inline-block",
          margin: "0",
          marginRight: "20px",
          padding: "0 23px 0 0",
          fontSize: "24px",
          fontWeight: "500",
          verticalAlign: "top",
          lineHeight: "49px",
        }}
      >
        404&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|
      </h1>
      <div
        style={{
          display: "inline-block",
          textAlign: "left",
          lineHeight: "49px",
          height: "49px",
          verticalAlign: "middle",
        }}
      >
        <h2
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "49px",
            margin: "0",
            padding: "0",
          }}
        >
          This page could not be found
        </h2>
      </div>
    </div>
  );
};
export default PageNotFound;
