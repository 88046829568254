import React, {  } from "react";
import StaticResourceText from "../Format/static-resource-text";
const PeopleIconSection = ({ icons, resources }) => {
  /* NOSONAR */

  const parse = require("html-react-parser");
  return (
    <div className="parallax-container">
      <section className="container">
        <div className="list-container">
          <h2 className="align-center center-content mt0 mb0">
            <StaticResourceText
              resources={resources}
              reskey={"PPL-ICON-TITLE"}
            ></StaticResourceText>
          </h2>
          <div className="icons__holder mb0 mt0">
            {icons &&
              icons.map((icon) => {
                return (
                  <div className="icons__content" key={icon.title}>
                    <img src={icon.image} alt={icon.title} />
                    {icon.shortDesc && parse(icon.shortDesc)}
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
export default PeopleIconSection;
