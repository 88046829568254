import React, { useState, useEffect } from 'react';
import CommonObj from '../CommonObj';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import carousel from '../../assets/js/Components/carousel';
import faq from '../../assets/js/Components/faq';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import parallax, { scrollRefresh } from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import formInit from '../../assets/js/Components/formInit';
import Parallax from '../Common/Parallax';
import Hero from '../Common/Hero';
import BlogTemplate from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import PageNotFound from '../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import PeopleCarouselSection from '../People/PeopleCarouselSection';
import PeopleIconSection from '../People/PeopleIconSection';
import PeoplePanelSection from '../People/PeoplePanelSection';
import OnOurMantelpiece from '../People/OnOurMantelpiece';
import PillarThumbnailTemplate from '../Common/PillarThumbnailTemplate';
import PeoplePanelTemplate from '../People/PeoplePanelTemplate';
import '../../components/loader.css';
import PreLoad from '../Common/preload';
import PeoplePgFunc from '../../assets/js/Components/PeoplePgFunc';
import BreadCrumb from '../Common/breadcrumb';

function People(props) {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState([0, 0]);
  const fetchData = async () => {
    let pageName = CommonObj.PageNameList.People;
    console.log(JSON.stringify(pageName));
    console.log(CommonObj.Endpoints.GetPage);
    let res = await fetch(CommonObj.Endpoints.GetPage, {
      method: 'POST',
      body: JSON.stringify(pageName),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let page = await res.json()
    setPage(page);
    PeoplePgFunc(page);
  }
  function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
  useEffect(() => {
    // fetch call used to be here
    fetchData();
    PageScroller();
  }, []);

  useEffect(() => {
    if (page != null) {
      heroCarousel();
      carousel();
      faq();
      formInit();
      parallax();
      scrollRefresh();
    }
    window.scrollTo(0, 0);
    hidePreLoad();
    resizeWindow();

  }, [loading]);


  if (page != null) {
    let heroCarouselList = page.contents.filter((x) => x.type == CommonObj.ContentType.Carousels && x.tags == "PPL-CAROUSELS-1");
    let peopleheroCarouselList = page.contents.filter((x) => x.type == CommonObj.ContentType.Carousels && x.tags == "PPL-CAROUSELS-2");
      let testimonials = page.contents.filter((x) => x.type == CommonObj.ContentType.Testimonials);
    let faqs = page.contents.filter((x) => x.type == CommonObj.ContentType.FAQS);
    let icons = page.contents.filter((x) => x.type == CommonObj.ContentType.Icons);
    let panels = page.contents.filter((x) => x.type == CommonObj.ContentType.Panels);
    let thumbnails = page.contents.filter((x) => x.type == CommonObj.ContentType.Thumbnails);
    let blogs = page.contents.filter((x) => x.type == CommonObj.ContentType.Blogs);

    return (
      <>
        {loading ? (<PreLoad></PreLoad>) : (
          <Section PageName={page.name} async>
            <Helmet>
              <title>{page.title}</title>
              <meta name="description" content={page.metaDescription} />
              {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
            </Helmet>

            <Parallax pageheroimage={page.image ? page.image : ""}></Parallax>
            <Parallax pageFixedImage1={page.backgroundImage ? page.backgroundImage : ""}></Parallax>
            <Hero landingpage={false} carouselList={heroCarouselList} heading1={page.heading} resources={props.resources} contactUs={false} page="people" shortDesc={page.shortDesc}></Hero>
            <BreadCrumb page={page?.name}/>
            <PeopleCarouselSection title={"GO Ahead! Here’s Why."} contents={peopleheroCarouselList}></PeopleCarouselSection>
            {!!getSection(icons, "PPL-ICON-SEC-1").length && (<PeopleIconSection icons={getSection(icons, "PPL-ICON-SEC-1")} resources={props.resources}></PeopleIconSection>)}
            {!!getTestimonialsByTitle(testimonials, "Meena Sinha").length && (<PeoplePanelSection testimonials={getTestimonialsByTitle(testimonials, "Meena Sinha")} title={"PPL-PANEL-GROW"} ></PeoplePanelSection>)}
            {!!panels.length && (<PeoplePanelTemplate panels={panels} classes={"people col"} ></PeoplePanelTemplate>)}
            {!!getThumbnails(thumbnails, "PPL-ICON-THUMB-1").length && (<PillarThumbnailTemplate thumbnails={getThumbnails(thumbnails, "PPL-ICON-THUMB-1")} isPeoplePage={true} isGap0={true}  ></PillarThumbnailTemplate>)}
            {!!getTestimonialsByTitle(testimonials, "Jayanthi Shivakumar").length && (<PeoplePanelSection testimonials={getTestimonialsByTitle(testimonials, "Jayanthi Shivakumar")} title={"PPL-PANEL-FIND-BEAT"}></PeoplePanelSection>)}
            {!!getSection(icons, "PPL-ICON-SEC-2").length && (<PillarThumbnailTemplate icons={getSection(icons, "PPL-ICON-SEC-2")} isIconTemplate={true}></PillarThumbnailTemplate>)}
            {!!getThumbnails(thumbnails, "PPL-ICON-THUMB-2").length && (<OnOurMantelpiece thumbnails={getThumbnails(thumbnails, "PPL-ICON-THUMB-2")} title={"PPL_ON_OUR_MANTEL_PIECE"} classes={"people col"}></OnOurMantelpiece>)}
            {!!blogs.length && (<BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={true} resources={props.resources}></BlogTemplate>)}
            {!!faqs.length && (<FAQTemplate faqs={faqs} notopspace={true} resources={props.resources} ></FAQTemplate>)}
          </Section>
        )}

      </>
    )
  } 
  else {
    return (
      <>
      <PreLoad></PreLoad>
      </>
    )
  }

  function getTestimonialsByTitle(testimonials, title) {
    return testimonials.filter((testimonial) => testimonial.title == title);
  }

  function getSection(icons, tag) {
    return icons.filter((icon) => icon.tags?.includes(tag));
  }

  function getThumbnails(thumbnails, tag) {
    return thumbnails.filter((thumbnail) => thumbnail.tags?.includes(tag));
  }
}


export default People;
