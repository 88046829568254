import React from "react";

const PreLoad = () => {
  return (
    <>
      <div async>
        <div className="preload" role="complementary">
          <div className="preload__icon">
            <img src="/assets/img/SVG/Triangles.svg" alt="triangles image" />
            <div className="preload__text">Loading...</div>
         
          </div>
        </div>
      </div>
     
    </>
  );
};
export default PreLoad;